<template>
  <div>
    <v-card class="ma-sm-10" outlined>
      <v-card-text class="text-center">
        <div class="font-weight-bold">08h10 : Accueil</div>
        <div class="font-weight-bold mt-5">
          08h30 - 09h00: Séance pléniaire
        </div>

        <div class="font-weight-bold mt-5">09h00 - 10h00 : Pitch des entreprises</div>
        <div class="font-weight-bold mt-5">10h15 - 12h00 : Speed datings</div>
        <div class="font-weight-bold mt-5">Dès 12h15 : Apéritif dînatoire</div>
      </v-card-text>
    </v-card>
    <agenda-form />
  </div>
</template>

<script>
import AgendaForm from "@/components/AgendaForm";

export default {
  components: { AgendaForm },
};
</script>

