<template>
  <v-card
    class="pa-md-10 ma-md-5 ma-sm-0 pa-sm-0"
    v-if="entreprises && entreprises[0] && agenda && student"
    outlined
  >
    <v-card-title class="text-center">{{ entreprises[0].nom }}</v-card-title>
    <v-card-subtitle>
      <a :href="entreprises[0].url" target="_blank">{{ entreprises[0].url }}</a>
    </v-card-subtitle>

    <v-card-text class="pa-0">
      <v-container class="pa-0">
        <v-row>
          <v-col
            ><v-card outlined style="border: none">
              <v-card-title class="subtitle-2"
                ><v-icon class="mr-3">mdi-information-outline</v-icon
                >Présentation</v-card-title
              >
              <v-card-text
                ><v-card outlined
                  ><v-card-text v-html="entreprises[0].presentation.html">
                  </v-card-text></v-card></v-card-text></v-card
          ></v-col>
        </v-row>
        <v-row v-if="entreprises[0].carte">
          <v-col
            ><v-card outlined style="border: none">
              <v-card-title class="subtitle-2"
                ><v-icon class="mr-3">mdi-map-marker-outline</v-icon
                >Adresse</v-card-title
              >
              <v-card-text>
                <v-card outlined>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <div id="map"></div>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <div>{{ entreprises[0].rue }}</div>
                          <div v-if="entreprises[0].rue2">
                            {{ entreprises[0].rue2 }}
                          </div>
                          <div>
                            {{ entreprises[0].codePostal }}
                            {{ entreprises[0].ville }}
                          </div>
                          <div v-if="entreprises[0].canton">
                            {{ entreprises[0].canton.nom }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card></v-col
          >
        </v-row>
        <v-row>
          <v-col
            ><v-card outlined style="border: none">
              <v-card-title class="subtitle-2"
                ><v-icon class="mr-3">mdi-account-tie</v-icon>Personnes de
                contact</v-card-title
              ><v-card-text>
                <v-card
                  outlined
                  class="mt-3"
                  v-for="personneContact in entreprises[0].personnesContact"
                  :key="personneContact.id"
                  ><v-card-title
                    >{{ personneContact.prenom }}
                    {{ personneContact.nom }}</v-card-title
                  ><v-card-subtitle>{{
                    personneContact.fonction
                  }}</v-card-subtitle
                  ><v-divider /><v-card-text
                    ><div class="title font-weight-bold mb-5">
                      Problématiques proposées
                    </div>

                    <div v-if="personneContact.problematiquesEE.length">
                      <div
                        v-for="(
                          problematiqueEE, i
                        ) in personneContact.problematiquesEE"
                        :key="problematiqueEE.id"
                        class="mt-2"
                      >
                        <v-chip
                          :color="
                            student.filiere === 'Economie d\'entreprise'
                              ? 'green'
                              : 'grey'
                          "
                          outlined
                          label
                        >
                          Économie d'entreprise
                        </v-chip>
                        <span
                          v-if="
                            problematiqueEE.domainesEE &&
                            problematiqueEE.domainesEE.length
                          "
                        >
                          <v-chip
                            x-small
                            class="ml-2"
                            v-for="domaineEE in problematiqueEE.domainesEE"
                            :key="`${problematiqueEE.id}-${domaineEE.nom}`"
                            >{{ domaineEE.nom }}</v-chip
                          >
                        </span>
                        <div class="mt-5">
                          <span class="subtitle-1 font-weight-bold">{{
                            problematiqueEE.titre
                          }}</span>
                        </div>
                        <div class="subtitle-2 mt-5">Contexte</div>
                        <div v-html="problematiqueEE.context.html"></div>
                        <div class="subtitle-2 mt-5">Description</div>
                        <div v-html="problematiqueEE.description.html"></div>
                        <v-divider
                          class="my-5"
                          v-if="i < personneContact.problematiquesEE.length - 1"
                        ></v-divider>
                      </div>
                    </div>
                    <hr
                      class="my-5"
                      v-if="
                        personneContact.problematiquesEE.length &&
                        personneContact.problematiquesIM.length
                      "
                    />
                    <div v-if="personneContact.problematiquesIM.length">
                      <div
                        v-for="(
                          problematiqueIM, i
                        ) in personneContact.problematiquesIM"
                        :key="problematiqueIM.id"
                        class="mt-2"
                      >
                        <v-chip
                          :color="
                            student.filiere === 'Ingénierie des médias'
                              ? 'success'
                              : 'grey'
                          "
                          outlined
                          label
                        >
                          Ingénierie des médias
                        </v-chip>
                        <span
                          v-if="
                            problematiqueIM.domainesIM &&
                            problematiqueIM.domainesIM.length
                          "
                        >
                          <v-chip
                            x-small
                            class="ml-2"
                            v-for="domaineIM in problematiqueIM.domainesIM"
                            :key="`${problematiqueIM.id}-${domaineIM.nom}`"
                            >{{ domaineIM.nom }}</v-chip
                          >
                        </span>
                        <div class="mt-5">
                          <span class="subtitle-1 font-weight-bold">{{
                            problematiqueIM.titre
                          }}</span>
                        </div>
                        <div class="subtitle-2 mt-5">Contexte</div>
                        <div v-html="problematiqueIM.context.html"></div>
                        <div class="subtitle-2 mt-5">Description</div>
                        <div v-html="problematiqueIM.description.html"></div>
                        <v-divider
                          class="my-5"
                          v-if="i < personneContact.problematiquesIM.length - 1"
                        ></v-divider>
                      </div>
                    </div> </v-card-text
                  ><v-divider />
                  <div>
                    <div class="pa-3" v-if="companies">
                      <div class="subtitle-2 mb-4 ml-1">
                        Prendre rendez-vous
                      </div>
                      <div>
                        <div
                          class="ml-1 error--text"
                          v-if="!hasProblematiques(personneContact, student)"
                        >
                          Aucune problématique proposée pour votre filière.
                        </div>
                        <div v-else>
                          <div
                            v-if="
                              !companies.find(
                                (c) => c.contactPersonId === personneContact.id
                              ).closed &&
                              !companies.find(
                                (c) => c.contactPersonId === personneContact.id
                              ).deadlineReached
                            "
                          >
                            <div
                              v-if="
                                !agenda.find(
                                  (a) =>
                                    a.contactPersonId === personneContact.id
                                )
                              "
                            >
                              <div
                                outlined
                                v-for="slot in companies
                                  .find(
                                    (c) =>
                                      c.contactPersonId === personneContact.id
                                  )
                                  .slots.filter(
                                    (s) =>
                                      agenda
                                        .map((a) => a.slots[0])
                                        .map((sl) =>
                                          isEqual(
                                            parseISO(sl.start),
                                            parseISO(s.start)
                                          )
                                        )
                                        .indexOf(true) === -1
                                  )"
                                :key="slot.id"
                                class="ma-2"
                              >
                                <v-btn outlined @click="selectSlot(slot._id)">{{
                                  format(parseISO(slot.start), "HH:mm")
                                }}</v-btn
                                ><v-btn
                                  outlined
                                  class="ml-3"
                                  v-if="slotId === slot._id"
                                  @click="
                                    bookSlot(
                                      companies.find(
                                        (c) =>
                                          c.contactPersonId ===
                                          personneContact.id
                                      )._id,
                                      slot.start,
                                      slot.end
                                    )
                                  "
                                  >Confirmer</v-btn
                                >
                              </div>
                            </div>
                            <div v-else class="ml-1">
                              <div>
                                Votre rendez-vous est confirmé de
                                {{
                                  format(
                                    parseISO(
                                      agenda.find(
                                        (a) =>
                                          a.contactPersonId ===
                                          personneContact.id
                                      ).slots[0].start
                                    ),
                                    "HH:mm"
                                  )
                                }}
                                à
                                {{
                                  format(
                                    parseISO(
                                      agenda.find(
                                        (a) =>
                                          a.contactPersonId ===
                                          personneContact.id
                                      ).slots[0].end
                                    ),
                                    "HH:mm"
                                  )
                                }}
                              </div>
                              <v-btn
                                v-show="false"
                                small
                                outlined
                                class="mt-5"
                                @click="
                                  unbookSlot(
                                    agenda.find(
                                      (a) =>
                                        a.contactPersonId === personneContact.id
                                    )._id
                                  )
                                "
                                >Annuler mon rendez-vous</v-btn
                              >
                            </div>
                          </div>
                          <div v-else class="ml-1">
                            <div
                              v-if="
                                companies.find(
                                  (c) =>
                                    c.contactPersonId === personneContact.id
                                ).deadlineReached
                              "
                            >Inscriptions terminées pour l'édition 2024.</div>
                            <div v-else>
                              Ouverture des inscriptions le
                              {{
                                format(
                                  parseISO(
                                    companies.find(
                                      (c) =>
                                        c.contactPersonId === personneContact.id
                                    ).pivotDate
                                  ),
                                  "dd.MM.yyyy à HH:mm"
                                )
                              }}.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div></v-card
                >
              </v-card-text>
            </v-card></v-col
          >
        </v-row>
      </v-container>
      <v-snackbar v-model="snackbar.visible" :color="snackbar.color" top right>
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.visible = false">
            Fermer
          </v-btn>
        </template>
      </v-snackbar>
    </v-card-text>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import mapboxgl from "mapbox-gl";
import axios from "axios";
import { parseISO, format, isEqual } from "date-fns";

export default {
  data: () => ({
    timeout: null,
    agenda: undefined,
    companies: undefined,
    slotId: undefined,
    parseISO,
    format,
    isEqual,
    snackbar: { text: "", color: "", visible: false },
  }),
  props: ["student"],
  methods: {
    selectSlot(id) {
      this.slotId = id;
    },
    hasProblematiques(personneContact, student) {
      return (
        (student.filiere === "Ingénierie des médias" &&
          personneContact.problematiquesIM.length) ||
        (student.filiere === "Economie d'entreprise" &&
          personneContact.problematiquesEE.length)
      );
    },
    async bookSlot(_id, start, end) {
      try {
        const { data: agenda } = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/companies/${_id}`,
          data: { start, end, action: "book" },
        });
        this.agenda = agenda;
        this.slotId = undefined;
      } catch (error) {
        this.snackbar = {
          text: error.response.data,
          color: "error",
          visible: true,
        };
      }
    },
    async unbookSlot(_id) {
      const { data: agenda } = await axios({
        method: "patch",
        url: `${process.env.VUE_APP_API_URI}/companies/${_id}`,
        data: { action: "unbook" },
      });
      this.agenda = agenda;
      const { data: company } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/companies/${_id}?type=mongo`,
      });
      this.companies.find((c) => c._id === _id).slots = company.slots;
    },
  },
  async mounted() {
    const { data: agenda } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/user-context/agenda`,
    });
    this.agenda = agenda;
    mapboxgl.accessToken =
      "pk.eyJ1IjoiY2xpZW5nbWUiLCJhIjoiY2t0aXJtaGppMTR5YTJ2am82ODBuOGM3OCJ9.xtCFAxrKOoIlrtT74Mspvg";
    this.timeout = setTimeout(async () => {
      if (this.entreprises[0].carte) {
        const map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v11",
          center: [
            this.entreprises[0].carte.longitude,
            this.entreprises[0].carte.latitude,
          ],
          zoom: 14,
          interactive: false,
        });
        new mapboxgl.Marker()
          .setLngLat([
            this.entreprises[0].carte.longitude,
            this.entreprises[0].carte.latitude,
          ])
          .addTo(map);
      }
      const companiesPromise = this.entreprises[0].personnesContact.map((pc) =>
        axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/companies/${pc.id}`,
        })
      );
      const companies = await Promise.all(companiesPromise);
      this.companies = companies.map((c) => c.data);
    }, 500);
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  apollo: {
    entreprises: {
      query: gql`
        query CompanyDetails($companyId: ID!) {
          entreprises(where: { id: $companyId }) {
            id
            nom
            url
            rue
            rue2
            codePostal
            ville
            personnesContact {
              id
              civilite {
                nom
              }
              nom
              prenom
              fonction
              email
              telephone
              problematiquesEE(where: { statut: accepte }) {
                titre
                description {
                  html
                }
                context {
                  html
                }
                domainesEE {
                  nom
                }
              }
              problematiquesIM(where: { statut: accepte }) {
                titre
                description {
                  html
                }
                context {
                  html
                }
                domainesIM {
                  nom
                }
              }
            }
            presentation {
              html
            }
            canton {
              nom
            }
            carte {
              latitude
              longitude
            }
          }
        }
      `,
      variables() {
        return {
          companyId: this.$route.params.id,
        };
      },
    },
  },
};
</script>

<style scoped>
#map {
  top: 0;
  bottom: 0;
  height: 300px;
  width: 100%;
}
</style>
