<template>
  <profile-form :student="student" />
</template>

<script>
import ProfileForm from "@/components/ProfileForm";

export default {
  components: { ProfileForm },
  props: ['student']
};
</script>

