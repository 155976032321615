var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-alert',{staticClass:"text-left",attrs:{"outlined":"","color":"primary","icon":"mdi-information-outline","prominent":"","border":"left"}},[_c('div',{staticClass:"my-2"},[_vm._v(" Pour rappel, les problématiques ne sont pas encore approuvées par le comité de validation des TB. Vous avez ainsi la possibilité de les modifier en fonction de vos discussions avec les entreprises. ")])])],1)],1),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Rechercher une entreprise..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[(_vm.entreprises)?_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item-group',[_vm._l((_vm.computedCompanies.sort(
            function (a, b) { return _vm.hasNoProblematiques(a) - _vm.hasNoProblematiques(b); }
          )),function(entreprise,index){return [_c('v-list-item',{key:entreprise.id,on:{"click":function($event){return _vm.$router.push(("/companies/" + (entreprise.id)))}},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [(_vm.hasNoProblematiques(entreprise))?_c('div',{staticClass:"mr-3"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-decagram-outline ")])]}}],null,true)},[_c('span',[_vm._v("Aucune problématique proposée pour votre filière")])])],1):_vm._e(),(!_vm.hasNoProblematiques(entreprise))?_c('div',{staticClass:"mr-3"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-decagram-outline ")])]}}],null,true)},[_c('span',[_vm._v("Une ou plusieurs problématiques proposées pour votre filière")])])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(entreprise.nom)}}),_c('v-list-item-subtitle',{staticClass:"text--primary",domProps:{"textContent":_vm._s(entreprise.presentation.text)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
                    entreprise.nombreEmployes
                      ? ((entreprise.nombreEmployes.nombre) + " employés")
                      : '-'
                  )}})],1),_c('v-list-item-action',[(entreprise.canton)?_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(entreprise.canton.nom)}}):_vm._e()],1)]}}],null,true)}),(
              index < _vm.computedCompanies.length - 1 &&
              _vm.computedCompanies.length > 1
            )?_c('v-divider',{key:index}):_vm._e()]})],2)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }