<template>
  <div>
    <v-row>
      <v-col>
        <v-alert
          outlined
          color="primary"
          icon="mdi-information-outline"
          prominent
          border="left"
          class="text-left"
        >
          <div class="my-2">
            Pour rappel, les problématiques ne sont pas encore approuvées par le
            comité de validation des TB. Vous avez ainsi la possibilité de les
            modifier en fonction de vos discussions avec les entreprises.
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <v-text-field
      outlined
      dense
      label="Rechercher une entreprise..."
      v-model="search"
    ></v-text-field>
    <v-card class="mx-auto" outlined>
      <v-list two-line v-if="entreprises">
        <v-list-item-group>
          <template
            v-for="(entreprise, index) in computedCompanies.sort(
              (a, b) => hasNoProblematiques(a) - hasNoProblematiques(b)
            )"
          >
            <v-list-item
              :key="entreprise.id"
              @click="$router.push(`/companies/${entreprise.id}`)"
            >
              <template v-slot:default="{}">
                <div class="mr-3" v-if="hasNoProblematiques(entreprise)">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">
                        mdi-alert-decagram-outline
                      </v-icon>
                    </template>
                    <span
                      >Aucune problématique proposée pour votre filière</span
                    >
                  </v-tooltip>
                </div>
                <div class="mr-3" v-if="!hasNoProblematiques(entreprise)">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="success" dark v-bind="attrs" v-on="on">
                        mdi-check-decagram-outline
                      </v-icon>
                    </template>
                    <span
                      >Une ou plusieurs problématiques proposées pour votre
                      filière</span
                    >
                  </v-tooltip>
                </div>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="entreprise.nom"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    class="text--primary"
                    v-text="entreprise.presentation.text"
                  ></v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-text="
                      entreprise.nombreEmployes
                        ? `${entreprise.nombreEmployes.nombre} employés`
                        : '-'
                    "
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text
                    v-if="entreprise.canton"
                    v-text="entreprise.canton.nom"
                  ></v-list-item-action-text>
                </v-list-item-action>
              </template>
            </v-list-item>
            <v-divider
              v-if="
                index < computedCompanies.length - 1 &&
                computedCompanies.length > 1
              "
              :key="index"
            ></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import gql from "graphql-tag";
export default {
  data: () => ({ search: "", entreprises: [] }),
  props: ["student"],
  async mounted() {
    const {
      data: { entreprises },
    } = await this.$apollo.query({
      query: gql`
        query loadCompanies {
          entreprises(orderBy: nom_ASC) {
            id
            nom
            presentation {
              text
            }
            canton {
              nom
            }
            personnesContact {
              problematiquesEE(where: { statut: accepte }) {
                titre
              }
              problematiquesIM(where: { statut: accepte }) {
                titre
              }
            }
            nombreEmployes {
              nombre
            }
          }
        }
      `,
      variables() {
        return {
          searchString: this.search,
        };
      },
    });
    this.entreprises = entreprises;
  },
  methods: {
    hasNoProblematiques(company) {
      let hasProblematiques = false;
      if (this.student.filiere === "Ingénierie des médias") {
        hasProblematiques =
          company.personnesContact
            .map((p) => p.problematiquesIM.length > 0)
            .indexOf(true) > -1;
      }

      if (this.student.filiere === "Economie d'entreprise") {
        hasProblematiques =
          company.personnesContact
            .map((p) => p.problematiquesEE.length > 0)
            .indexOf(true) > -1;
      }
      return !hasProblematiques;
    },
  },
  computed: {
    computedCompanies() {
      return this.entreprises
        .filter((e) => {
          return e.personnesContact.reduce(
            (acc, x) =>
              acc.concat([...x.problematiquesEE, ...x.problematiquesIM]),
            []
          ).length;
        })
        .filter(
          (c) => c.nom.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
    },
  },
};
</script>
