var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.student)?_c('v-card',{staticClass:"pa-md-10 pa-sm-0",attrs:{"outlined":""}},[_c('v-container',[_c('v-row',{staticClass:"text-center"},[_c('v-col',[(!_vm.student.picture)?_c('v-avatar',{staticClass:"white--text text-h5",staticStyle:{"cursor":"pointer"},attrs:{"color":"grey","size":"86"},domProps:{"textContent":_vm._s(_vm.shortName)},on:{"click":_vm.handleFileImport}}):_c('v-avatar',{staticClass:"white--text text-h5",staticStyle:{"cursor":"pointer"},attrs:{"size":"86"},on:{"click":_vm.handleFileImport}},[_c('img',{attrs:{"src":(_vm.apiUri + "/" + (_vm.student.picture)),"alt":"student-picture"}})]),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file"},on:{"change":_vm.onFileChanged}}),_c('div',{staticClass:"headline mt-4"},[_vm._v(" "+_vm._s(_vm.student.firstname)+" "+_vm._s(_vm.student.lastname)+" ")]),_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.student.filiere))])],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"top":"","right":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar.visible = false}}},'v-btn',attrs,false),[_vm._v(" Fermer ")])]}}],null,false,474744036),model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")]),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.cropDialog),callback:function ($$v) {_vm.cropDialog=$$v},expression:"cropDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Recadrer l'image")]),_c('v-card-text',{staticClass:"text--center"},[_c('cropper',{ref:"cropper",staticClass:"cropper",attrs:{"src":_vm.cropSrc,"stencil-props":{
              handlers: {},
              movable: false,
              scalable: false,
            },"stencil-size":{
              width: 280,
              height: 280,
            },"image-restriction":"stencil"},on:{"change":_vm.onChange}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","dense":""},on:{"click":_vm.uploadPicture}},[_vm._v("OK")])],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }