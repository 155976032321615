import Vue from "vue";
import VueRouter from "vue-router";
import Profile from "@/views/Profile.vue";
import Companies from "@/views/Companies.vue";
import CompanyDetails from "@/views/CompanyDetails.vue";
import Agenda from "@/views/Agenda.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/profile",
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/companies",
    name: "Companies",
    component: Companies,
  },
  {
    path: "/companies/:id",
    name: "Companies Details",
    component: CompanyDetails,
  },
  {
    path: "/agenda",
    name: "Agenda",
    component: Agenda,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
