<template>
  <div v-if="agenda && contactPersons" class="ma-sm-10">
    <v-card
      outlined
      :ripple="false"
      v-for="slot in slots"
      :key="slot.toString()"
      class="mt-5"
      :disabled="
        !agenda.find((a) =>
          a.slots.find((s) => isEqual(parseISO(s.start), slot))
        )
      "
      :to="
        agenda.find((a) =>
          a.slots.find((s) => isEqual(parseISO(s.start), slot))
        )
          ? `/companies/${
              contactPersons.find(
                (cp) =>
                  cp.id ===
                  agenda.find((a) =>
                    a.slots.find((s) => isEqual(parseISO(s.start), slot))
                  ).contactPersonId
              ).entreprises[0].id
            }`
          : ''
      "
    >
      <v-card-title
        v-if="
          agenda.find((a) =>
            a.slots.find((s) => isEqual(parseISO(s.start), slot))
          )
        "
        >{{
          contactPersons.find(
            (cp) =>
              cp.id ===
              agenda.find((a) =>
                a.slots.find((s) => isEqual(parseISO(s.start), slot))
              ).contactPersonId
          ).prenom
        }}
        {{
          contactPersons.find(
            (cp) =>
              cp.id ===
              agenda.find((a) =>
                a.slots.find((s) => isEqual(parseISO(s.start), slot))
              ).contactPersonId
          ).nom
        }}
        <span class="body-2 ml-1">
          ({{
            contactPersons.find(
              (cp) =>
                cp.id ===
                agenda.find((a) =>
                  a.slots.find((s) => isEqual(parseISO(s.start), slot))
                ).contactPersonId
            ).fonction
          }},
          {{
            contactPersons.find(
              (cp) =>
                cp.id ===
                agenda.find((a) =>
                  a.slots.find((s) => isEqual(parseISO(s.start), slot))
                ).contactPersonId
            ).entreprises[0].nom
          }})</span
        ></v-card-title
      >
      <v-card-title v-else>Libre</v-card-title>
      <v-card-subtitle
        >{{ format(slot, "HH:mm") }}-{{
          format(addMinutes(slot, 10), "HH:mm")
        }}</v-card-subtitle
      >
    </v-card>
  </div>
</template>

<script>
import {
  setDate,
  setHours,
  setMinutes,
  setMonth,
  setYear,
  eachMinuteOfInterval,
  format,
  isEqual,
  parseISO,
  addMinutes,
} from "date-fns";
import axios from "axios";
import gql from "graphql-tag";

export default {
  async mounted() {
    const date = setYear(setMonth(setDate(new Date(), 1), 9), 2024);
    const start = setMinutes(setHours(date, 10), 15);
    const end = setMinutes(setHours(date, 11), 59);
    const result = eachMinuteOfInterval({ start, end });
    const currentSlots = result
      .filter((r, index) => index % 15 === 0)
      .slice(0, 8);
    let newSlots = currentSlots.map((slot, index) => {
      if (index > 3) {
        slot = addMinutes(slot, 5);
      }
      return slot;
    });
    this.slots = newSlots;

    const { data: agenda } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/user-context/agenda`,
    });
    this.agenda = agenda;
    const {
      data: { personnesContact },
    } = await this.$apollo.query({
      query: gql`
        query personnesContact($ids: [ID!]) {
          personnesContact(where: { id_in: $ids }) {
            id
            nom
            prenom
            fonction
            entreprises {
              id
              nom
            }
            teamschannel
          }
        }
      `,
      variables: {
        ids: this.agenda.map((a) => a.contactPersonId),
      },
    });
    this.contactPersons = personnesContact;
  },
  data: () => ({
    slots: [],
    agenda: undefined,
    contactPersons: undefined,
    format,
    isEqual,
    parseISO,
    addMinutes,
  }),
};
</script>
